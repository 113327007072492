import React, {
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import ButtonArrow from "../buttonArrow";
import ListingFilters from "@components/shared/listingFilters";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import styles from "./styles.module.scss";
import { questions } from "@components/shared/listingFilters/questions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/reducers";
import { arrayToKeyedObject } from "@lib/sharedUtils";
import { initiateQuestionnaireQuestions } from "@redux/shared/actions";

export default function SellingForm(): ReactElement {
    const dispatch = useDispatch();
    const [error, setError] = useState(false);

    const router = useRouter();
    const { category } = useDomainContext();

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);

    const { questions: filters } = useSelector(
        (state: RootState) => state.rootReducer.shared.listing,
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const gettingFilters = () => {
            const finalFilters: { [x: string]: string } = {};

            Object.keys(filters).forEach((filterKey) => {
                const value = filters[filterKey].value;
                const name = filters[filterKey].name;

                if (!value) {
                    return;
                }

                finalFilters[name] = value;
            });

            return btoa(JSON.stringify(finalFilters));
        };

        if (filters["zipCode"].value.length >= 4) {
            const zipCode = filters["zipCode"].value;
            hiddenInputRef.current?.focus();
            const formattedZipCode =
                zipCode.length === 4 ? `0${zipCode}` : zipCode;

            window.location.href = `${window.location.origin}/${
                category?.slugAlias ?? ""
            }/offers?zc=${formattedZipCode}&filters=${gettingFilters()}&referrer=${
                category?.slugAlias ?? ""
            }&${gettingQueriesAsString(router.query, "inline", ["category"])}`;
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        dispatch(
            initiateQuestionnaireQuestions(
                arrayToKeyedObject(
                    questions[category?.slugAlias as "auto"].filter(
                        (question) => question.isInHomePage,
                    ) as [],
                    "name",
                ),
            ),
        );
        setTimeout(() => {
            document.getElementsByName("zipCode")[0]?.focus();
        }, 100);
    }, [category]);

    return (
        <>
            <form className={styles["form"]} onSubmit={handleSubmit}>
                <ListingFilters
                    isQuestionSameRow={true}
                    noMinimize
                    className={styles["filters"]}
                    selectQuestionClassName={styles["select-question"]}
                    toggleQuestionClassName={styles["toggle-question"]}
                    textQuestionClassName={styles["text-question"]}
                />

                <div>
                    <button
                        type="submit"
                        name="zipCodeSubmit"
                        id="zipCodeSubmit"
                        className={`${styles["submit-button"]} py-1.5 px-3 rounded md:py-2.5 md:px-8 md:text-lg`}
                    >
                        <span>Get Started</span>
                        <ButtonArrow />
                    </button>
                </div>

                {error && (
                    <span className={styles["error"]}>
                        Please enter a valid zipCode
                    </span>
                )}
            </form>
            <div>
                <input
                    type="number"
                    ref={hiddenInputRef}
                    className="select-none opacity-0 absolute -top-48 pointer-events-none"
                    autoComplete="off"
                />
            </div>
        </>
    );
}
