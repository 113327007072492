import React from "react";

export default function UsaFlag() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="#1B447F"
            />
            <path
                d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5Z"
                fill="white"
            />
            <path
                d="M9.475 10.085H18.5C18.5003 9.34323 18.4 8.60484 18.202 7.89H9.475V10.085ZM9.475 5.695H17.231C16.6989 4.85246 16.0233 4.10958 15.235 3.5H9.475V5.695ZM9.851 18.5C11.7992 18.5054 13.6937 17.8615 15.235 16.67H4.467C6.00835 17.8615 7.90281 18.5054 9.851 18.5V18.5ZM2.471 14.476H17.231C17.6601 13.7953 17.9872 13.0554 18.202 12.28H1.5C1.717 13.063 2.046 13.8 2.471 14.476Z"
                fill="#D80027"
            />
            <path
                d="M5.437 2.827H6.212L5.492 3.351L5.767 4.198L5.047 3.674L4.326 4.198L4.563 3.466C3.92769 3.99538 3.37257 4.61416 2.915 5.303H3.163L2.704 5.637C2.633 5.756 2.564 5.877 2.499 6L2.718 6.674L2.309 6.377C2.20702 6.59295 2.11426 6.81315 2.031 7.037L2.272 7.78H3.163L2.443 8.303L2.718 9.15L1.998 8.627L1.566 8.94C1.523 9.287 1.5 9.641 1.5 10H10V1.5C8.38346 1.49729 6.79998 1.9578 5.437 2.827V2.827ZM5.767 9.15L5.047 8.627L4.326 9.15L4.601 8.303L3.881 7.78H4.771L5.046 6.933L5.321 7.78H6.212L5.492 8.303L5.767 9.15V9.15ZM5.49 5.827L5.766 6.674L5.046 6.15L4.325 6.674L4.6 5.827L3.88 5.303H4.77L5.045 4.457L5.32 5.303H6.211L5.491 5.827H5.49ZM8.816 9.15L8.096 8.627L7.375 9.15L7.65 8.303L6.93 7.78H7.82L8.095 6.933L8.37 7.78H9.26L8.54 8.303L8.816 9.15V9.15ZM8.54 5.827L8.816 6.674L8.096 6.15L7.375 6.674L7.65 5.827L6.93 5.303H7.82L8.095 4.457L8.37 5.303H9.26L8.54 5.827V5.827ZM8.54 3.351L8.816 4.198L8.096 3.674L7.375 4.198L7.65 3.351L6.93 2.827H7.82L8.095 1.98L8.37 2.827H9.26L8.54 3.351Z"
                fill="#0052B4"
            />
        </svg>
    );
}
