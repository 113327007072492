import React, { ReactElement } from "react";
import Image from "next/image";
import style from "./style.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import SellingForm from "../sellingForm";

const logos = [
    {
        src: "/insureyourcar/assets/images/progressive-logoOpt.png",
        width: 80,
    },
    { src: "/insureyourcar/assets/images/american_familyOpt.png", width: 60 },
    { src: "/insureyourcar/assets/images/geicoOpt.png", width: 60 },
    { src: "/insureyourcar/assets/images/libertyOpt.png", width: 80 },
    { src: "/insureyourcar/assets/images/rootOpt.png", width: 50 },
];

export default function MainBanner(): ReactElement {
    const { formattedTitle, visitDetails, category } = useDomainContext();

    const state = visitDetails?.region;

    return (
        <>
            <div className={style.bg}>
                <div className={`${style.mainBannerBg} flex items-start`}>
                    <div
                        className={`${style.roundedBox} rounded-xl max-w-none mx-4 md:mx-auto md:max-w-[736px] `}
                    >
                        <h1 className={`${style.mainTitle} text-center`}>
                            {formattedTitle ? (
                                formattedTitle
                            ) : (
                                <>
                                    Unlock Great{" "}
                                    <span
                                        className={`${style.highlight} font-black`}
                                    >
                                        {category?.name}
                                    </span>{" "}
                                    Savings
                                </>
                            )}
                        </h1>

                        <p
                            className={`${style.mainDesc} text-base text-center mx-auto mb-8 lg:mb-0`}
                        >
                            Start saving on {category?.name} today! Complete the
                            brief form below to discover the best car insurance
                            rates.
                        </p>

                        <SellingForm />

                        {state && (
                            <p
                                className={`${style.smallTxt} text-center font-normal text-base mb-4`}
                            >
                                Show {state} {category?.name} Deals
                            </p>
                        )}

                        <div
                            className={`${style.logos} flex items-center flex-wrap justify-center mx-auto`}
                        >
                            {logos.map((logo, index) => (
                                <div
                                    key={index}
                                    className={`${style.imgBlock} mx-4 md:mx-2`}
                                >
                                    <Image
                                        src={logo.src}
                                        width={`${logo.width}`}
                                        height="20"
                                        alt=""
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={style.mainBannerCar}>
                        <Image
                            src="/insureyourcar/assets/images/carOpt.png"
                            width="550"
                            height="364"
                            alt=""
                            layout="intrinsic"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
